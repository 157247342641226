import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const useLocalStorageState = <T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) as T : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default useLocalStorageState;