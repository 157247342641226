import axios from 'axios'
class Api {
    static BASE_URL = process.env.REACT_APP_API

    private static get(url: string) {
        return axios.get(url, {
            responseType: 'json',
        })
            .then(response => response.data)
            .catch(error => console.log(error))
    }

    static getAll() {
        const url = `${this.BASE_URL}/all`
        return this.get(url)
    }
}
export default Api