import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import useLocalStorageState from './useLocalStorageState'
import Api from './api'
import './custom.css'

function App() {
  const [cpus, setCpus] = useState<Array<any>>([])
  const [withOffersOnly, setWithOffersOnly] = useLocalStorageState('withOffersOnly', false)
  const [sortBy, setSortBy] = useLocalStorageState<'multi' | 'single'>('sortBy', 'multi')
  const [sorted, setSorted] = useState(false)
  type RangeType = { min: number | null, max: number | null };
  type FiltersType = { multimark: RangeType, singlemark: RangeType, price: RangeType };
  const [filters, setFilters] = useLocalStorageState<FiltersType>('filters', { multimark: { min: null, max: null }, singlemark: { min: null, max: null }, price: { min: null, max: null } })

  useEffect(() => {
    Api.getAll().then(result => {
      setCpus(result)
      setSorted(false)
    })
  }, [])

  useEffect(() => {
    if (!sorted) {
      if (sortBy === 'single') {
        setCpus(cpus => [...cpus].sort((a, b) => {
          const singlemark1 = a.singlemark || 0;
          const singlemark2 = b.singlemark || 0;
          if (singlemark1 < singlemark2) return 1;
          if (singlemark1 > singlemark2) return -1;
          return 0
        }))
      } else {
        setCpus(cpus => [...cpus].sort((a, b) => {
          const multimark1 = a.multimark || 0;
          const multimark2 = b.multimark || 0;
          if (multimark1 < multimark2) return 1;
          if (multimark1 > multimark2) return -1;
          return 0
        }))
      }
      console.log('snorting by', sortBy)
      setSorted(true)
    }
  }, [sortBy, sorted])

  const handleWithOffersOnlyClick = useCallback(() => {
    setWithOffersOnly(state => !state)
  }, [])

  const handleSortByClick = useCallback(() => {
    setSortBy(state => state === 'multi' ? 'single' : 'multi')
    setSorted(false)
  }, [])

  const handleFiltersChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters((filters: FiltersType) => {
      let newFilters = { ...filters };
      const value = parseInt(event.target.value);
      switch (event.target.name) {
        case "multimarkMin":
          newFilters.multimark.min = value;
          break;
        case "multimarkMax":
          newFilters.multimark.max = value;
          break;
        case "singlemarkMin":
          newFilters.singlemark.min = value;
          break;
        case "singlemarkMax":
          newFilters.singlemark.max = value;
          break;
        case "priceMin":
          newFilters.price.min = value;
          break;
        case "priceMax":
          newFilters.price.max = value;
          break;
      }
      return newFilters;
    })
  }

  useEffect(() => {
    // filter the list with filters duh
  }, [filters]);

  return <>
    <div className='m-2 py-1 px-2 border inline-block' onClick={handleWithOffersOnlyClick}>offers only: {withOffersOnly ? 'true' : 'false'}</div>
    <div className='m-2 py-1 px-2 border inline-block' onClick={handleSortByClick}>sort by: {sortBy}</div>
    <div className='m-2 py-1 pl-2 pr-1 border inline-block' >Multimark:
      <input className="w-20 px-1 text-[#121212] mx-1" name="multimarkMin" type="number" value={filters.multimark.min?.toString()} onChange={handleFiltersChange} />
      <input className="w-20 px-1 text-[#121212]" name="multimarkMax" type="number" value={filters.multimark.max?.toString()} onChange={handleFiltersChange} />
    </div>
    <div className='m-2 py-1 pl-2 pr-1 border inline-block' >Singlemark:
      <input className="w-20 px-1 text-[#121212] mx-1" name="singlemarkMin" type="number" value={filters.singlemark.min?.toString()} onChange={handleFiltersChange} />
      <input className="w-20 px-1 text-[#121212]" name="singlemarkMax" type="number" value={filters.singlemark.max?.toString()} onChange={handleFiltersChange} />
    </div>
    <div className='m-2 py-1 pl-2 pr-1 border inline-block' >Price:
      <input className="w-20 px-1 text-[#121212] mx-1" name="priceMin" type="number" value={filters.price.min?.toString()} onChange={handleFiltersChange} />
      <input className="w-20 px-1 text-[#121212]" name="priceMax" type="number" value={filters.price.max?.toString()} onChange={handleFiltersChange} />
    </div>
    <div className='p-2'>{cpus.map((cpu: any, i) => {
      if (withOffersOnly && (!cpu.hasOwnProperty('olxOffers') || cpu.olxOffers.length == 0) && (!cpu.hasOwnProperty('ceneoOffers') || cpu.ceneoOffers.length == 0))
        return <></>
      if (filters.multimark.min && cpu.multimark < filters.multimark.min)
        return <></>
      if (filters.multimark.max && cpu.multimark > filters.multimark.max)
        return <></>
      if (filters.singlemark.min && cpu.singlemark < filters.singlemark.min)
        return <></>
      if (filters.singlemark.max && cpu.singlemark > filters.singlemark.max)
        return <></>
      if (filters.price.min) {
        let priceMax = 0;
        [...cpu.ceneoOffers, ...cpu.olxOffers].forEach((offer:any) => {
          if (offer.price > priceMax) 
            priceMax = offer.price;
        });
        if (priceMax < filters.price.min)
          return <></>
      }
      if (filters.price.max){
        let priceMin = Infinity;
        [...cpu.ceneoOffers, ...cpu.olxOffers].forEach((offer:any) => {
          if (offer.price < priceMin)
            priceMin = offer.price;
        });
        if (priceMin > filters.price.max)
          return <></>
      }
      
      return <div key={i} className='border-b border-[#f8f8f8] mb-2 pb-2'>
        <div>{cpu.name}</div>
        <div>Multimark: {cpu.multimark}</div>
        <div>Singlemark: {cpu.singlemark}</div>
        {cpu.averageOlxOffer && <div>Olx average: {cpu.averageOlxOffer}zł</div>}
        {cpu.cheapestOlxOffer && <div>Olx cheapest: {cpu.cheapestOlxOffer}zł</div>}
        {cpu.averageCeneoOffer && <div>Ceneo average: {cpu.averageCeneoOffer}zł</div>}
        {cpu.cheapestCeneoOffer && <div>Ceneo cheapest: {cpu.cheapestCeneoOffer}zł</div>}
        {cpu.ceneoOffers && cpu.ceneoOffers.length > 0 && <><div>Ceneo offers:</div>
          <ol className='pl-2 list-decimal ml-3'>
            {cpu.ceneoOffers && cpu.ceneoOffers.map((offer: any, j: number) => <li key={j}>{offer.name} {offer.price}zł</li>)}
          </ol></>}
        {cpu.olxOffers && cpu.olxOffers.length > 0 && <><div>Olx offers:</div>
          <ol className='pl-2 list-decimal ml-3'>
            {cpu.olxOffers && cpu.olxOffers.map((offer: any, j: number) => <li><a href={offer.url} key={j}>{offer.name} {offer.price}zł</a></li>)}
          </ol></>}
      </div>
    })}</div>
  </>
}

export default App;
